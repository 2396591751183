import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from 'components/ReflexBox';
import { Link } from 'gatsby';

import CenterContent from 'components/CenterContent';
import { H2, H3, Text, TextLight } from 'components/Typo';
import Icon from 'components/Icon';
import { getThemeSpacePx, getThemeColor, media } from 'utils/css-utils';

const Wrapper = styled(Box)`
  background: ${(p) =>
    p.highlightBg ? p.theme.colors.background : 'transparent'};

  a,
  a:visited {
    color: ${getThemeColor('text')};
  }

  a:hover,
  a:active,
  a:focus {
    color: ${getThemeColor('red')};
  }
`;

const ColumnText = styled(Box)`
  margin-bottom: ${getThemeSpacePx(4)};

  ${media.s`
    column-count: 2;
    column-gap: ${getThemeSpacePx(3)};
  `}
`;

export default ({
  icon,
  title,
  text,
  skills = [],
  projects = [],
  ...props
}) => {
  return (
    <Wrapper py={[5, 7]} {...props}>
      <CenterContent>
        <Flex alignItems="center">
          <Icon size={32} name={icon} />
          <H2 style={{ marginLeft: 16 }}>{title}</H2>
        </Flex>

        <ColumnText>
          <TextLight>{text}</TextLight>
        </ColumnText>

        <Flex mx={[0, -1]} flexWrap="wrap" flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} px={[0, 1]}>
            <H3>Services & Skills</H3>
            {skills.map((s) => (
              <Flex key={s} mb={2}>
                <Icon
                  colorizeStroke
                  name="arrow_right"
                  style={{ flexShrink: 0, top: -1, position: 'relative' }}
                  mr={2}
                  size={28}
                />
                <Text>{s}</Text>
              </Flex>
            ))}
          </Box>
          <Box width={[1, 1 / 2]} px={1} mt={[3, 0]}>
            <H3>Example Projects</H3>
            {projects.map((p) => {
              const internal = /^\/(?!\/)/.test(p.href);
              const linkProps = internal
                ? {
                    as: Link,
                    to: p.href,
                  }
                : { as: 'a', href: p.href };

              return (
                <Flex key={p.label} mb={2} flexDirection={['column', 'row']}>
                  <Text {...linkProps}>{p.label}</Text>
                  <TextLight ml={[0, 'auto']}>{p.client}</TextLight>
                </Flex>
              );
            })}
          </Box>
        </Flex>
      </CenterContent>
    </Wrapper>
  );
};
