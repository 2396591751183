import React from 'react';
import { Flex, Box } from 'components/ReflexBox';
import styled from '@emotion/styled';

import { H3, TextLight } from 'components/Typo';
import Icon from 'components/Icon';

import { getThemeSpacePx } from 'utils/css-utils';

const ItemTitle = styled(H3)`
  margin: ${getThemeSpacePx(2)} 0;
`;

const Item = ({ title, icon, text }) => (
  <Box width={[1, 1, 1 / 2, 1 / 2, 1 / 4]} px={[2, 2, 2, 2, 2]} py={2}>
    <Box bg="violetLighten5" px={4} pt={4} pb={4} height="100%">
      <Icon size={67} noCSS name={icon} />
      <ItemTitle my={1}>{title}</ItemTitle>
      <TextLight>{text}</TextLight>
    </Box>
  </Box>
);

const HowWeWork = () => {
  return (
    <Flex mx={[0, 0, -2, -2, -2]} flexWrap="wrap">
      <Item
        title="Precise"
        icon="precise"
        text="We want your project to stand out from others by using the latest technologies while keeping attention to the smallest details."
      />
      <Item
        title="Adaptive"
        icon="adaptive"
        text="By working in the news sector for many years we have learned to adapt quickly to changes and new ideas."
      />
      <Item
        title="Efficient"
        icon="efficient"
        text="Instead of lengthy meetings we prefer direct communication and focusing on the result to make your project better."
      />
      <Item
        title="Open"
        icon="open"
        text="We keep you up to date on every stage of your project with a high-level of transparency."
      />
    </Flex>
  );
};

export default HowWeWork;
